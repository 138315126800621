import { Button, Container, Flex, Spacer, Text, VStack } from "@chakra-ui/react";
import { Link as GatsbyLink } from 'gatsby';
import React from "react";
import { randomLie } from '../lie-utils';

function LiePage({ pageContext }) {
    const { claim, location, date, repeated_count, repeated_ids, analysis, category } = pageContext
    const nextLie = randomLie();
    const paragraphs = analysis.replace('\n\n', '<br/><br/>')

    return (
        <Container maxWidth="100%" p={0}>
            <VStack spacing={8}>
                <Button as={GatsbyLink} to={`/lie/${nextLie.id}`}>More wisdom</Button>
                <Container maxWidth="90%">
                    <VStack spacing={4}>
                        <Text fontSize="xl" fontWeight="bold">{claim}</Text>
                        <Container maxWidth="100%" p={0}>
                            <Flex>
                                <Text>&mdash; {location}, {date}</Text>
                                <Spacer />
                            </Flex>
                        </Container>
                    </VStack>
                </Container>
                <Container maxWidth="100%" p={0}>
                    <Text fontSize="sm" dangerouslySetInnerHTML={{ __html: paragraphs }} />
                </Container>
            </VStack>
        </Container>
    )
}

export default LiePage;
